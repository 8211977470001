<script>
import { nextTick } from 'vue'
import ui from '/~/core/ui'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import PaymentsAccounts from '/~/components/payments/bank-accounts/payments-bank-accounts.vue'
import PaymentsCreditCards from '/~/components/payments/credit-cards/payments-credit-cards.vue'
import PaymentsEwallet from '/~/components/payments/ewallet/payments-ewallet.vue'
import PaymentMethodBlock from '/~/components/payments/payments-methods-block.vue'
import PaymentsPba from '/~/components/payments/pba/payments-pba.vue'
import PaymentsPoints from '/~/components/payments/points/payments-points.vue'
import { useCheckout } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'cart-menu-payment-methods',
  components: {
    BaseLoader,
    BaseButton,
    BaseAsidePage,
    PaymentsPoints,
    PaymentsEwallet,
    PaymentsCreditCards,
    PaymentsAccounts,
    PaymentsPba,
    PaymentMethodBlock,
  },
  props: {
    close: {
      type: Function,
      required: true,
    },
    meta: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { configLoading, isPbaEnabled, hasUnavailableMethods } =
      usePaymentMethods()
    const { splitPaymentOrderAllowed, isBillPaymentsTemplate } = useProvider()
    const {
      hasSelectedPaymentMethods,
      isPointsDisabled,
      isEwalletDisabled,
      payment,
    } = useCheckout()

    return {
      configLoading,
      splitPaymentOrderAllowed,
      isPbaEnabled,
      hasSelectedPaymentMethods,
      isPointsDisabled,
      isEwalletDisabled,
      ui,
      isBillPaymentsTemplate,
      payment,
      hasUnavailableMethods,
    }
  },
  computed: {
    backRoute() {
      if (this.meta.back) {
        return this.meta.back
      } else if (this.isBillPaymentsTemplate) {
        return { name: 'purchase-checkout' }
      }

      return { hash: '#cart-checkout' }
    },
    to() {
      return this.meta.to || 'cart-menu-modal'
    },
    splitEnabled() {
      return this.splitPaymentOrderAllowed(FlowType.purchase)
    },
  },
  methods: {
    onAddMethod() {
      if (this.hasUnavailableMethods(this.payment.orderType)) {
        this.$router.push({ hash: '#profile-payment-methods' })
      } else {
        const route = this.meta.addMethod || {
          hash: '#cart-payment-methods-add',
        }

        this.$router.push(route)
      }
    },
    onConfirm() {
      this.$router.push(this.backRoute)
    },
    onSelect(value) {
      if (!this.splitPaymentOrderAllowed(FlowType.purchase) && value) {
        this.onConfirm()
      }
    },
    setFocus() {
      nextTick(() => {
        this.$refs.baseAsidePage.setFocus()
      })
    },
  },
}
</script>

<template>
  <base-aside-page
    title="Select Payment Method"
    :back="backRoute"
    :action="payment.canAddPaymentMethods && onAddMethod"
    no-padding
  >
    <div class="flex h-full max-h-full flex-col overflow-hidden">
      <div class="flex-1 overflow-auto px-5 pt-[15px]">
        <base-loader v-if="configLoading" class="mt-[30px] w-full" />

        <div v-else class="mt-[5px] space-y-6">
          <payments-points
            :to="to"
            :split-enabled="splitEnabled"
            @select="onSelect"
          />
          <payments-ewallet
            :to="to"
            :split-enabled="splitEnabled"
            @select="onSelect"
          />
          <payments-credit-cards
            :to="to"
            :split-enabled="splitEnabled"
            @select="onSelect"
          />
          <payments-accounts
            :to="to"
            :split-enabled="splitEnabled"
            @select="onSelect"
          />
          <payment-method-block v-if="isPbaEnabled" title="Pay By Account">
            <payments-pba />
          </payment-method-block>
        </div>
      </div>

      <div class="p-[15px]">
        <base-button
          full-width
          :disabled="!hasSelectedPaymentMethods"
          @click="onConfirm"
        >
          <span>Confirm</span>
        </base-button>
      </div>
    </div>
  </base-aside-page>
</template>
